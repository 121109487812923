import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"meal-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardTitle,{staticClass:"justify-center flex-column"},[_c(VAvatar,{staticClass:"mb-4",class:_vm.mealData.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":_vm.mealData.avatar ? '' : 'primary',"size":"120","rounded":""}},[_c(VImg,{attrs:{"src":_vm.mealData.image}})],1),_c('span',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.mealData.name_en))])],1),_c(VCardText,[_c('h2',{staticClass:"text-xl font-weight-semibold mb-2"},[_vm._v(" Details ")]),_c(VDivider),_c(VList,[_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Name (En):")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.mealData.name_en))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Name (Ar):")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.mealData.name_ar))])]),_c(VCardActions,{staticClass:"justify-center pt-5"},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.isBioDialogOpen = !_vm.isBioDialogOpen}}},[_vm._v(" Edit ")]),_c(VBtn,{attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.handleDelete(_vm.mealData.id)}}},[_vm._v(" Delete ")])],1)],1)],1)],1),_c('meals-bio-edit',{attrs:{"is-bio-dialog-open":_vm.isBioDialogOpen,"meal-data":_vm.mealData},on:{"update:isBioDialogOpen":function($event){_vm.isBioDialogOpen=$event},"update:is-bio-dialog-open":function($event){_vm.isBioDialogOpen=$event},"refresh":_vm.updateMealsData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }