<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="meals-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit Meal Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating Meal details will receive a privacy audit.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form
          ref="form"
          v-model="valid"
          class="multi-col-validation"
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="mealDataLocal.name_en"
                :rules="[validators.required]"
                outlined
                dense
                maxLength="100"
                label="Name (En)"
              ></v-text-field>
              <v-checkbox
                v-model="mealDataLocal.add_ones"
                :label="`Add-Ons`"
              ></v-checkbox>

              <v-checkbox
                v-model="mealDataLocal.used_in_all_meals"
                :label="`Used in all meals`"
              ></v-checkbox>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="mealDataLocal.name_ar"
                outlined
                dense
                maxLength="100"
                :rules="[validators.required]"
                label="Name (Ar)"
              ></v-text-field>

              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Choose an image"
                outlined
                dense
                label="Upload Image"
                hide-details="auto"
                class="mb-6 mt-6"
                @change="handleChange($event)"
              ></v-file-input>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open',false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { emailValidator, integerValidator, required } from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    mealData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isBillingAddress = ref(true)

    const mealDataLocal = ref({})

    mealDataLocal.value = JSON.parse(JSON.stringify(props.mealData))

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetMealData = () => {
      mealDataLocal.value = JSON.parse(JSON.stringify(mealDataLocal))
      resetForm()
    }

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']

    // on form submit
    const onSubmit = async () => {
      if (mealDataLocal.value.image === props.mealData.image) delete mealDataLocal.value.image
      if (valid.value) {
        try {
          const data = await store.dispatch('app-meals/editMeal', mealDataLocal.value)
          const responseData = data.data.data.category
          emit('refresh', responseData)
          emit('update:is-bio-dialog-open', false)
        } catch (error) {
          if (error?.response?.data?.message) {            
            Object.entries(error.response.data.message).forEach(err => {
              Swal.fire({
                icon: 'error',
                title: `${err[0]}`,
                text: `${err[1][0]}`,
                footer:
                  'Desired dimensions: min-width: 126px; max-width: 252px; min-height: 95px; max-height: 189px; max-file-size: 512kb',
              })
            })
          }
        }
      } else {
        validate()
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        mealDataLocal.value = JSON.parse(JSON.stringify(props.mealData))
      },
    )

    const handleChange = e => {
      mealDataLocal.value.image = e
    }

    return {
      isBillingAddress,
      statusOptions,
      languageOptions,
      countries,
      mealDataLocal,
      resetMealData,
      form,
      valid,
      validate,
      onSubmit,
      handleChange,

      // validation
      validators: { required, emailValidator, integerValidator },
    }
  },
}
</script>
