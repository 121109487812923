<template>
  <div id="meal-view">
    <v-row>
      <v-col
        cols="12"
      >
        <meal-bio-panel
          :meal-data="mealData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refresh="udpateMealData"
        ></meal-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline } from '@mdi/js'
import mealsStoreModule from '../mealsStoreModule'
import mealBioPanel from './meal-bio-panel/MealsBioPanel.vue'

export default {
  components: {
    mealBioPanel,
  },
  methods: {
    udpateMealData(value) {
      this.mealData = value
    },
  },
  setup() {
    const MEALS_APP_STORE_MODULE_NAME = 'app-meals'

    // Register module
    if (!store.hasModule(MEALS_APP_STORE_MODULE_NAME)) {
      store.registerModule(MEALS_APP_STORE_MODULE_NAME, mealsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEALS_APP_STORE_MODULE_NAME)) store.unregisterModule(MEALS_APP_STORE_MODULE_NAME)
    })

    const mealData = ref({})
    const mealTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-meals/fetchMeal', { id: router.currentRoute.params.id })
      .then(response => {
        mealData.value = response.data.data
        selectedPlan.value = response.data.currentPlan
      })
      .catch(error => {
        if (error.response.status === 404) {
          mealData.value = {}
        }
      })

    const tabs = [{ icon: mdiAccountOutline, title: 'Overview' }]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      tabs,
      mealTab,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      mealData,
      resolveCurrentPlanValue,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
